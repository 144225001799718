<template>
    <layout-heading>
        <template #heading-cta>
            <router-link v-if="bookLink" :to="bookLink" class="view-in-book">
                View This Page In Book
            </router-link>
        </template>

        <portal to="title">
            <router-link to="/core">
                <img src="../assets/images/book.svg" alt="">
                <span>{{ folder }}/{{ file }}</span>
            </router-link>
        </portal>

        <BookContentExtended
            class="faq-page"
            :address="address"
            :network="network"
            :folder="folder"
            :file="file"
        />
    </layout-heading>
</template>

<script>
import BookContentExtended from "@/components/BookContentExtended";

export default {
    components: {BookContentExtended},
    props: {
        name: String,
        query: Object,
        hash: String
    },
    data() {
        return {};
    },
    computed: {
        file() {
            if (!this.path || this.path.endsWith('/')) {
                return null;
            }
            let index = this.path.lastIndexOf('/');
            if (index > -1) {
                return this.path.slice(index + 1);
            }
        },
        folder() {
            if (!this.path) {
                return null;
            }
            let index = this.path.lastIndexOf('/');
            if (index < 0) {
                return this.path;
            }
            return this.path.slice(0, index);
        },
        address() {
            return this.bwns ? this.bwns.address : "";
        },
        network() {
            return this.bwns ? this.bwns.network : "";
        },
        test() {
            return this.bwns;
        },
        path() {
            return decodeURIComponent(this.hash.slice(1));
        }
    },
    asyncComputed: {
        async bwns() {
            if (this.name) {
                return this.$bwns.resolve(this.name, 'book');
            }
            const query = this.query;
            if (query.contract) {
                let ns = await this.$bwns.forContractAddress('book', query.contract, query.net);

                if (ns) {
                    return ns;
                }
            }

            return {
                address: query.contract,
                network: query.net
            }
        },
        async bookLink() {
            if (this.bwns) {
                let hash = this.hash.slice(1);
                if (this.bwns.name) {
                    return {
                        path: `/book/${this.bwns.name}`,
                        hash
                    };
                } else {
                    return {
                        path: '/book',
                        query: {
                            net: this.network,
                            contract: this.address
                        },
                        hash
                    }
                }
            }

            return null;
        }
    }
}
</script>

<style>
.view-in-book {
    display: block;
    margin: 8px 0;
}
</style>
