<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>Materials</span>
        </portal>

        <BookContentExtended
            class="materials-page"
            address="0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A"
            network="goerli"
            folder="Materials"
            blocks
        />
    </layout-heading>
</template>

<script>
import {getTokenBalances} from "@/assets/lib/util";
import BookContentExtended from "@/components/BookContentExtended";
import ProgressCircle from "@/components/ProgressCircle";
import {mapGetters, mapState} from "vuex";
import * as interval from "@/lib/interval";
import equal from 'fast-deep-equal';

export default {
    components: {ProgressCircle, BookContentExtended},
}
</script>

<style lang="scss">
.materials-page .markdown-body {
    display: grid;

    grid-gap: 25px;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

    .btn {
        font-size: 14px;
        padding: 4px 8px;
    }
}
</style>
