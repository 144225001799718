<template>
    <div class="book-content">
        <div v-if="noAccessError" class="no-access">
            Decrypting some of the content failed because
            <template v-if="loggedIn">
                you don't have the necessary access.
            </template>
            <template v-else>
                you are not signed in.
                <div class="sign-in-wrap">
                    <button class="btn btn-sm btn-primary" @click="login">Sign In</button>
                </div>
            </template>
        </div>
        <div v-if="content" class="markdown-body" ref="markdown"></div>
        <div v-else-if="contentRendered" class="book-content-default">
            <slot name="default"></slot>
        </div>
        <div v-else>
            <ProgressCircle/>
        </div>
    </div>
</template>

<script>
import Md from "@/assets/lib/md-extended";
import {getNetwork} from "@/assets/lib/networks";
import BookContentMixin from "@/components/BookContentMixin";
import ProgressBar from "@/components/ProgressBar";
import ProgressCircle from "@/components/ProgressCircle";
import {loadBookContent, loadFile, loadFiles} from "@/views/happs/book/lib/booklib";
import CryptMixin from "@/views/happs/core/lib/CryptMixin";
import Mustache from "mustache";
import {mapActions} from "vuex";

// Includes support for graphing and other big extensions
export default {
    name: "BookContentExtended",
    components: {ProgressCircle},
    mixins: [CryptMixin, BookContentMixin],
    created() {
        Md().then(it => this.md = it);
    },
    methods: {
        login() {
            this.$bus.emit('login_request', {
                message: ""
            });
        }
    }
}
</script>


<style lang="scss" scoped>
@import "~@/assets/css/custom.scss";

.no-access {
    padding-bottom: 15px;
    color: $danger;
}

.sign-in-wrap {
    margin-top: 15px;
}
</style>
