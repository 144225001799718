<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>FAQ</span>
        </portal>

        <BookContentExtended
            class="faq-page"
            address="0xaEEeBd6ca46BEf69689F043681fEe5F9961D199A"
            network="goerli"
            folder="FAQ"
            />
    </layout-heading>
</template>

<script>
    import BookContentExtended from "@/components/BookContentExtended";
    export default {
        components: {BookContentExtended},
        data() {
            return {};
        }
    }
</script>

<style scoped>

</style>
